// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  external: icon('ic_external'),
  calendar: icon('ic_calendar'),
};

const navConfig = [
  {
    items: [
      { title: 'Início', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'Usuários', path: PATH_DASHBOARD.user.list, icon: ICONS.user, roles: ['Admin', 'Operador'] },
      { title: 'Eventos', path: PATH_DASHBOARD.event.list, icon: ICONS.calendar, roles: ['Admin', 'Operador'] },
    ],
  },
];

export default navConfig;
