import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 238,
        height: 72,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="238" height="72">
        <path d="M0 0 C78.54 0 157.08 0 238 0 C238 23.76 238 47.52 238 72 C159.46 72 80.92 72 0 72 C0 48.24 0 24.48 0 0 Z " fill="#040302" transform="translate(0,0)" />
        <path d="M0 0 C78.54 0 157.08 0 238 0 C238 23.76 238 47.52 238 72 C159.46 72 80.92 72 0 72 C0 48.24 0 24.48 0 0 Z M4 3 C4 24.78 4 46.56 4 69 C79.9 69 155.8 69 234 69 C234 47.22 234 25.44 234 3 C158.1 3 82.2 3 4 3 Z " fill="#C49A5E" transform="translate(0,0)" />
        <path d="M0 0 C3.7217819 2.57661824 6.34476412 4.77419042 8 9 C8.7208838 15.19772829 8.61934678 20.71018548 5 26 C1.51049882 27.74475059 -2.20354453 27.71470405 -6 27 C-8.80237413 24.86704545 -10.42926677 22.14146646 -12 19 C-12.14851844 17.06640414 -12.22130833 15.12657903 -12.25 13.1875 C-12.27578125 12.14980469 -12.3015625 11.11210938 -12.328125 10.04296875 C-11.95408344 6.57417855 -11.07568054 4.77889007 -9 2 C-5.45870816 -0.36086122 -4.17249782 -0.57269578 0 0 Z " fill="#040302" transform="translate(154,22)" />
        <path d="M0 0 C2.89740622 -0.13539281 5.78845621 -0.23426829 8.6875 -0.3125 C9.50412109 -0.35439453 10.32074219 -0.39628906 11.16210938 -0.43945312 C15.57830527 -0.5288498 17.42772072 -0.42576418 21.08203125 2.29296875 C23 5 23 5 23 8.625 C22.06579141 11.77795399 21.58725064 13.08768431 19 15 C20.63771328 20.40445384 24.19845018 25.4596461 29.1953125 28.20703125 C29.79085937 28.46871094 30.38640625 28.73039062 31 29 C31 29.33 31 29.66 31 30 C27.83788441 30.58079674 25.14359215 30.74847432 22 30 C19.09460523 27.35576431 17.07010071 24.32018923 15 21 C13.20180184 17.5739739 13.20180184 17.5739739 9 17 C9.33 20.3 9.66 23.6 10 27 C10.99 27.33 11.98 27.66 13 28 C13 28.66 13 29.32 13 30 C8.71 30 4.42 30 0 30 C1 28 1 28 4 27 C4 19.08 4 11.16 4 3 C2.68 2.67 1.36 2.34 0 2 C0 1.34 0 0.68 0 0 Z " fill="#B9925A" transform="translate(106,21)" />
        <path d="M0 0 C3.24941569 -0.82963805 5.76532031 -0.95715105 9 0 C12.59409 3.59409 15.21351733 7.76717606 18 12 C19.33590253 13.7639818 20.68797657 15.51594328 22.0625 17.25 C23.5165625 19.10625 23.5165625 19.10625 25 21 C25 15.06 25 9.12 25 3 C23.68 2.67 22.36 2.34 21 2 C21 1.34 21 0.68 21 0 C24.96 0 28.92 0 33 0 C33 0.66 33 1.32 33 2 C32.01 2.33 31.02 2.66 30 3 C29.67 11.91 29.34 20.82 29 30 C23.50039725 28.90007945 23.12808384 28.5397561 20.01171875 24.30859375 C18.95307617 22.89223633 18.95307617 22.89223633 17.87304688 21.44726562 C17.15181641 20.45404297 16.43058594 19.46082031 15.6875 18.4375 C14.57665039 16.94186523 14.57665039 16.94186523 13.44335938 15.41601562 C11.61672137 12.9530005 9.80674728 10.47764286 8 8 C8 14.27 8 20.54 8 27 C9.32 27.33 10.64 27.66 12 28 C12 28.66 12 29.32 12 30 C8.04 30 4.08 30 0 30 C0 29.34 0 28.68 0 28 C1.32 28 2.64 28 4 28 C4 19.75 4 11.5 4 3 C2.68 2.67 1.36 2.34 0 2 C0 1.34 0 0.68 0 0 Z " fill="#BC945B" transform="translate(71,21)" />
        <path d="M0 0 C3.5677811 2.35149209 5.84221079 4.99692157 7.75 8.8125 C8.41676914 14.69318522 8.12694895 19.92266627 5.140625 25.1171875 C2.12067854 28.79880763 -1.72719555 30.35896212 -6.3359375 31.1875 C-11.45758124 31.5014705 -14.44081293 30.10225247 -18.25 26.8125 C-21.84269879 23.0307118 -22.57824744 19.94625137 -22.8125 14.875 C-22.63788353 10.02186637 -21.93897014 6.83327103 -18.73046875 3.1484375 C-13.0864159 -1.94461283 -6.91362933 -2.5261338 0 0 Z M-16.25 5.8125 C-18.33839158 9.98928316 -17.69531459 15.26435365 -17.25 19.8125 C-15.91899789 23.13054319 -13.76738292 25.29511708 -11.25 27.8125 C-8.21235384 28.31681963 -5.29310861 28.26192707 -2.25 27.8125 C0.78215675 25.61418636 1.56654398 24.36286806 2.75 20.8125 C3.25976619 15.4892039 3.42052598 11.15355196 1 6.3125 C-2.83612627 2.01722067 -2.83612627 2.01722067 -8.1875 0.3125 C-12.26103263 0.97756655 -13.65263771 2.69566525 -16.25 5.8125 Z " fill="#B68F59" transform="translate(159.25,21.1875)" />
        <path d="M0 0 C3.63 0 7.26 0 11 0 C10.319375 0.680625 9.63875 1.36125 8.9375 2.0625 C8.49019531 2.51753906 8.04289062 2.97257812 7.58203125 3.44140625 C6.44274037 4.56381874 5.26862205 5.65126758 4.07421875 6.71484375 C3.10935547 7.59849609 3.10935547 7.59849609 2.125 8.5 C1.50882813 9.0465625 0.89265625 9.593125 0.2578125 10.15625 C-1.23653988 11.96166168 -1.23653988 11.96166168 -0.94140625 14.421875 C0.1914128 17.52420102 1.69555336 19.33372478 3.9375 21.75 C4.64777344 22.52859375 5.35804687 23.3071875 6.08984375 24.109375 C10.18382746 28.16149793 11.06227167 27 18 27 C18 19.08 18 11.16 18 3 C17.01 2.67 16.02 2.34 15 2 C15 1.34 15 0.68 15 0 C18.96 0 22.92 0 27 0 C27 0.66 27 1.32 27 2 C25.68 2 24.36 2 23 2 C23.33 10.25 23.66 18.5 24 27 C25.32 27.33 26.64 27.66 28 28 C28 28.66 28 29.32 28 30 C24.31332998 30.20474001 20.62813257 30.32809831 16.9375 30.4375 C15.89916016 30.49615234 14.86082031 30.55480469 13.79101562 30.61523438 C6.18518089 30.78425292 6.18518089 30.78425292 2.79736328 28.54248047 C1.01537945 26.78018707 -0.50227822 25.00694198 -2 23 C-2.5144165 22.37182373 -3.02883301 21.74364746 -3.55883789 21.09643555 C-5 19.25 -5 19.25 -7 16 C-5.56973592 11.70920776 -2.51170645 9.40372742 0.75 6.375 C2.25659086 4.90383382 2.25659086 4.90383382 3 2 C2.01 2 1.02 2 0 2 C0 1.34 0 0.68 0 0 Z " fill="#B68F59" transform="translate(41,21)" />
        <path d="M0 0 C0.66 0.33 1.32 0.66 2 1 C1.67 3.31 1.34 5.62 1 8 C0.34 8 -0.32 8 -1 8 C-1 6.35 -1 4.7 -1 3 C-3.97 3 -6.94 3 -10 3 C-10 5.64 -10 8.28 -10 11 C-9.319375 11.226875 -8.63875 11.45375 -7.9375 11.6875 C-3.86644958 13.50647997 0.94528984 15.89057968 3 20 C3.375 22.8125 3.375 22.8125 3 26 C-1.89093335 31.79189475 -1.89093335 31.79189475 -6.05859375 32.203125 C-7.23615234 32.22632812 -7.23615234 32.22632812 -8.4375 32.25 C-9.21996094 32.27578125 -10.00242188 32.3015625 -10.80859375 32.328125 C-13 32 -13 32 -14.7734375 30.68359375 C-16.59705814 28.18047116 -16.20023449 26.00351741 -16 23 C-15.34 23 -14.68 23 -14 23 C-13.4225 23.99 -12.845 24.98 -12.25 26 C-11.5075 26.99 -10.765 27.98 -10 29 C-6.87237733 29.58442963 -6.87237733 29.58442963 -4 29 C-2.69773905 27.90080265 -2.69773905 27.90080265 -2.6328125 25.19921875 C-2.68895076 21.88005581 -2.68895076 21.88005581 -5.1171875 19.76953125 C-6.02726563 19.12371094 -6.93734375 18.47789063 -7.875 17.8125 C-14.66850829 12.90607735 -14.66850829 12.90607735 -15.5 8.125 C-14.92960054 4.56000335 -13.82029633 3.06159297 -10.92578125 0.90625 C-7.4124689 -0.74707346 -3.78351594 -0.52969223 0 0 Z " fill="#B69059" transform="translate(188,20)" />
        <path d="M0 0 C0.66 0.33 1.32 0.66 2 1 C2 3.31 2 5.62 2 8 C1.34 8 0.68 8 0 8 C-0.66 6.35 -1.32 4.7 -2 3 C-3.99954746 2.95745644 -6.00041636 2.95919217 -8 3 C-9.30574766 4.10235476 -9.30574766 4.10235476 -9.3671875 6.828125 C-9.32616414 10.11765479 -9.32616414 10.11765479 -6.8828125 12.046875 C-5.51769531 12.88992187 -5.51769531 12.88992187 -4.125 13.75 C0.97674419 16.97674419 0.97674419 16.97674419 3 19 C3.68503937 26.07874016 3.68503937 26.07874016 1.1875 29.4375 C-1.84773502 31.60552501 -4.35436526 32.15297694 -8 32.1875 C-9.0209375 32.20876953 -9.0209375 32.20876953 -10.0625 32.23046875 C-12 32 -12 32 -15 30 C-15.6875 26.375 -15.6875 26.375 -16 23 C-15.34 22.67 -14.68 22.34 -14 22 C-13.649375 22.969375 -13.29875 23.93875 -12.9375 24.9375 C-11.25584915 28.36513824 -11.25584915 28.36513824 -7.4375 28.9375 C-4.13990053 29.26303331 -4.13990053 29.26303331 -2 28 C-1.54513057 25.17936167 -1.54513057 25.17936167 -2 22 C-4.35233982 19.61078299 -4.35233982 19.61078299 -7.4375 17.75 C-12.93714286 14.12571429 -12.93714286 14.12571429 -14 12 C-14.68825162 4.8880666 -14.68825162 4.8880666 -12.0625 1.5 C-7.98566153 -0.49681884 -4.4684466 -0.53551128 0 0 Z " fill="#BB945B" transform="translate(211,20)" />
        <path d="M0 0 C3.96 0 7.92 0 12 0 C12 0.66 12 1.32 12 2 C10.68 2 9.36 2 8 2 C8.33 10.25 8.66 18.5 9 27 C10.32 27.33 11.64 27.66 13 28 C13 28.66 13 29.32 13 30 C8.71 30 4.42 30 0 30 C0.99 29.01 1.98 28.02 3 27 C3.4137098 24.38845909 3.4137098 24.38845909 3.29296875 21.45703125 C3.28330078 20.38388672 3.27363281 19.31074219 3.26367188 18.20507812 C3.23853516 17.08552734 3.21339844 15.96597656 3.1875 14.8125 C3.17396484 13.68134766 3.16042969 12.55019531 3.14648438 11.38476562 C3.11109958 8.58936683 3.06172948 5.79492108 3 3 C2.01 2.67 1.02 2.34 0 2 C0 1.34 0 0.68 0 0 Z " fill="#BF965C" transform="translate(56,21)" />
        <path d="M0 0 C3.96 0 7.92 0 12 0 C12 0.66 12 1.32 12 2 C11.01 2.33 10.02 2.66 9 3 C9 10.92 9 18.84 9 27 C9.99 27.33 10.98 27.66 12 28 C12 28.66 12 29.32 12 30 C8.04 30 4.08 30 0 30 C0 29.34 0 28.68 0 28 C0.99 27.67 1.98 27.34 3 27 C3.33 18.75 3.66 10.5 4 2 C2.68 2 1.36 2 0 2 C0 1.34 0 0.68 0 0 Z " fill="#BE965C" transform="translate(25,21)" />
        <path d="M0 0 C6 0 6 0 7.875 1.0625 C9.64273274 4.1069286 9.36823916 6.5631012 9 10 C7.875 11.9375 7.875 11.9375 6 13 C4.02 13 2.04 13 0 13 C0 8.71 0 4.42 0 0 Z " fill="#050403" transform="translate(115,23)" />
        <path d="M0 0 C3.96 0 7.92 0 12 0 C12 0.66 12 1.32 12 2 C11.01 2.33 10.02 2.66 9 3 C8.67 11.58 8.34 20.16 8 29 C7.67 29 7.34 29 7 29 C6.87625 28.030625 6.7525 27.06125 6.625 26.0625 C6.315625 24.5465625 6.315625 24.5465625 6 23 C5.34 22.67 4.68 22.34 4 22 C4 15.73 4 9.46 4 3 C2.68 2.67 1.36 2.34 0 2 C0 1.34 0 0.68 0 0 Z " fill="#A58252" transform="translate(92,21)" />
        <path d="M0 0 C3.63 0 7.26 0 11 0 C10.319375 0.680625 9.63875 1.36125 8.9375 2.0625 C8.48632812 2.51496094 8.03515625 2.96742187 7.5703125 3.43359375 C6.4451972 4.55591026 5.31430749 5.67244952 4.1796875 6.78515625 C3.56351563 7.39230469 2.94734375 7.99945313 2.3125 8.625 C1.73113281 9.19476563 1.14976563 9.76453125 0.55078125 10.3515625 C-1.20251898 12.12382974 -1.20251898 12.12382974 -3 15 C-3.33 14.01 -3.66 13.02 -4 12 C-2.8203125 10.28125 -2.8203125 10.28125 -1.125 8.5 C1.05018304 6.19329426 1.97333496 5.07999512 3 2 C2.01 2 1.02 2 0 2 C0 1.34 0 0.68 0 0 Z M-6 13 C-5.34 13.33 -4.68 13.66 -4 14 C-4.99 14.66 -5.98 15.32 -7 16 C-6.67 15.01 -6.34 14.02 -6 13 Z " fill="#B28C57" transform="translate(41,21)" />
        <path d="M0 0 C0.78503906 0.00644531 1.57007813 0.01289063 2.37890625 0.01953125 C4.4375 0.1875 4.4375 0.1875 6.4375 1.1875 C6.4375 3.4975 6.4375 5.8075 6.4375 8.1875 C5.7775 8.1875 5.1175 8.1875 4.4375 8.1875 C3.7775 6.5375 3.1175 4.8875 2.4375 3.1875 C-2.43914009 2.83916856 -5.33300005 3.77064288 -9.5625 6.1875 C-7.49609172 1.41886551 -5.17313072 -0.09374701 0 0 Z " fill="#AF8A56" transform="translate(206.5625,19.8125)" />
        <path d="M0 0 C0.4125 0.556875 0.825 1.11375 1.25 1.6875 C3.12256399 4.16195956 5.05339344 6.5835229 7 9 C5.1875 9.625 5.1875 9.625 3 10 C0 8 0 8 -0.75 4.4375 C-0.8325 3.303125 -0.915 2.16875 -1 1 C-0.67 0.67 -0.34 0.34 0 0 Z " fill="#A88553" transform="translate(196,42)" />
        <path d="M0 0 C0.66 0 1.32 0 2 0 C3.65 2.97 5.3 5.94 7 9 C4.0625 8.625 4.0625 8.625 1 8 C-0.35439668 5.29120665 -0.06501451 2.99066732 0 0 Z " fill="#AC8854" transform="translate(172,43)" />
        <path d="M0 0 C1.45823212 -0.0270043 2.91660675 -0.04639787 4.375 -0.0625 C5.18710938 -0.07410156 5.99921875 -0.08570312 6.8359375 -0.09765625 C9 0 9 0 11 1 C10.67 3.31 10.34 5.62 10 8 C9.34 8 8.68 8 8 8 C8 6.35 8 4.7 8 3 C5.36 2.34 2.72 1.68 0 1 C0 0.67 0 0.34 0 0 Z " fill="#A78452" transform="translate(179,20)" />
        <path d="M0 0 C0.99 0.33 1.98 0.66 3 1 C2.38125 1.598125 1.7625 2.19625 1.125 2.8125 C-1.13676954 5.00811144 -1.13676954 5.00811144 -3 8 C-3.33 7.01 -3.66 6.02 -4 5 C-2.0625 2.3125 -2.0625 2.3125 0 0 Z M-6 6 C-5.34 6.33 -4.68 6.66 -4 7 C-4.99 7.66 -5.98 8.32 -7 9 C-6.67 8.01 -6.34 7.02 -6 6 Z " fill="#A78453" transform="translate(41,28)" />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
