import { Navigate, useRoutes } from 'react-router-dom';
// auth
import { UsersProvider } from '../contexts/UsersContext';
import { EventsProvider } from '../contexts/EventsContext';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import RoleBasedGuard from '../auth/RoleBasedGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import LoginLayout from '../layouts/login/LoginLayout';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page403,
  Page404,
  Page500,
  // Dashboard
  GeneralAppPage,
  // User
  UserListPage,
  UserEditPage,
  UserProfilePage,
  UserCreatePage,
  UserAccountPage,
  // Event
  EventListPage,
  EventEditPage,
  EventConfirmationPage,
  EventCredentialPage,
  EventCreatePage,
  LoginPage,
  ResetPasswordPage
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <LoginLayout>
                <ResetPasswordPage />
              </LoginLayout>
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'app', element: (
            <EventsProvider>
              <UsersProvider>
                <GeneralAppPage />
              </UsersProvider>
            </EventsProvider>
          )
        },
        {
          path: 'user',
          children: [
            {
              path: 'list', element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Operador']}>
                  <UsersProvider>
                    <UserListPage />
                  </UsersProvider>
                </RoleBasedGuard>
              )
            },
            {
              path: ':name/edit', element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Operador']}>
                  <UsersProvider>
                    <UserEditPage />
                  </UsersProvider>
                </RoleBasedGuard>
              )
            },
            {
              path: ':name/profile', element: (
                <UsersProvider>
                  <UserProfilePage />
                </UsersProvider>
              )
            },
            {
              path: 'new', element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Operador']}>
                  <UsersProvider>
                    <UserCreatePage />
                  </UsersProvider>
                </RoleBasedGuard>
              )
            },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'event',
          children: [
            {
              path: 'list', element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Operador']}>
                  <EventsProvider>
                    <EventListPage />
                  </EventsProvider>
                </RoleBasedGuard>
              )
            },
            {
              path: ':name/edit', element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Operador']}>
                  <EventsProvider>
                    <EventEditPage />
                  </EventsProvider>
                </RoleBasedGuard>
              )
            },
            {
              path: 'new', element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Operador']}>
                  <EventsProvider>
                    <EventCreatePage />
                  </EventsProvider>
                </RoleBasedGuard>
              )
            },
            {
              path: ':name/confirmation', element: (
                <EventsProvider>
                  <UsersProvider>
                    <EventConfirmationPage />
                  </UsersProvider>
                </EventsProvider>
              )
            },
            {
              path: ':name/credential', element: (
                <EventsProvider>
                  <UsersProvider>
                    <EventCredentialPage />
                  </UsersProvider>
                </EventsProvider>
              )
            },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: '403', element: <Page403 /> },
        { path: '404', element: <Page404 /> },
        { path: '500', element: <Page500 /> }
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}