// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_AUTH = {
  login: '/login',
  resetPassword: '/reset-password',
  newPassword: '/new-password',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app')
  },
  user: {
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    profile: (name) => path(ROOTS_DASHBOARD, `/user/${name}/profile`),
  },
  event: {
    new: path(ROOTS_DASHBOARD, '/event/new'),
    list: path(ROOTS_DASHBOARD, '/event/list'),
    account: path(ROOTS_DASHBOARD, '/event/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/event/${name}/edit`),
    confirmation: (name) => path(ROOTS_DASHBOARD, `/event/${name}/confirmation`),
    credential: (name) => path(ROOTS_DASHBOARD, `/event/${name}/credential`),
  },
};
