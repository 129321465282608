import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import axiosInstance from '../utils/axios'
import { PATH_DASHBOARD } from '../routes/paths';
import errorHandling from '../utils/errorHandling';

EventsProvider.propTypes = {
  children: PropTypes.node,
};

export const EventsContext = createContext({})

export const useEventsContext = () => {
  const context = useContext(EventsContext);

  if (!context) throw new Error('useEventsContext must be use inside EventsProvider');

  return context;
};

export function EventsProvider({ children }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const getEvent = useCallback(async (id) => {
    try {
      const response = await axiosInstance.get(`api/v1/Evento/${id}`)
      return response.data.data;
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
      return null;
    }
  }, [enqueueSnackbar])

  const getEvents = useCallback(async () => {
    try {
      const response = await axiosInstance.get('api/v1/Evento')
      return response.data.data;
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
      return null;
    }
  }, [enqueueSnackbar])

  const getDashboard = useCallback(async () => {
    try {
      const response = await axiosInstance.get('api/v1/Evento/dashboard')
      return response.data.data;
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
      return null;
    }
  }, [enqueueSnackbar])

  const getCredential = useCallback(async (id) => {
    try {
      const response = await axiosInstance.get(`api/v1/Evento/credencial/${id}`)
      return response.data.data;
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
      return null;
    }
  }, [enqueueSnackbar])

  const getCredentials = useCallback(async (id) => {
    try {
      const response = await axiosInstance.get(`api/v1/Evento/credenciais/${id}`)
      return response.data.data;
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
      return null;
    }
  }, [enqueueSnackbar])

  const createEvent = useCallback(async (event) => {
    try {
      await axiosInstance.post('api/v1/Evento', event)
      enqueueSnackbar('Evento cadastrado com sucesso!');
      navigate(PATH_DASHBOARD.event.list);
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
    }
  }, [enqueueSnackbar, navigate])

  const updateEvent = useCallback(async (event) => {
    try {
      await axiosInstance.put('api/v1/Evento', event)
      enqueueSnackbar('Evento atualizado com sucesso!');
      navigate(PATH_DASHBOARD.event.list);
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
    }
  }, [enqueueSnackbar, navigate])

  const deleteEvent = useCallback(async (id) => {
    try {
      await axiosInstance.delete(`api/v1/Evento/${id}`)
      enqueueSnackbar('Evento deletado com sucesso!');
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
    }
  }, [enqueueSnackbar])

  const registerEvent = useCallback(async (model) => {
    try {
      await axiosInstance.post('api/v1/Evento/inscrever', model)
      enqueueSnackbar('Inscrição confirmada com sucesso!');
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
    }
  }, [enqueueSnackbar])

  const confirmEvent = useCallback(async (id) => {
    try {
      await axiosInstance.post(`api/v1/Evento/confirmar/${id}`)
      enqueueSnackbar('Presença confirmada com sucesso!');
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
    }
  }, [enqueueSnackbar])

  const exportData = useCallback(async (id) => {
    try {
      const response = await axiosInstance.get(`api/v1/Evento/exportar/${id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = 'PlanilhaInscritos.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      enqueueSnackbar('Planilha gerada com sucesso!');
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
    }
  }, [enqueueSnackbar])

  const value = useMemo(
    () => ({
      getEvent,
      getEvents,
      getDashboard,
      getCredential,
      getCredentials,
      createEvent,
      updateEvent,
      deleteEvent,
      registerEvent,
      confirmEvent,
      exportData
    }), [
      getEvent, 
      getEvents, 
      getDashboard, 
      getCredential, 
      getCredentials, 
      createEvent, 
      updateEvent, 
      deleteEvent, 
      registerEvent, 
      confirmEvent, 
      exportData]);

  return (
    <EventsContext.Provider
      value={value}
    >
      {children}
    </EventsContext.Provider>
  )
}
