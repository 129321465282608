import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Container, Typography, Button } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
//
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
};

export default function RoleBasedGuard({ hasContent, roles, children }) {
  // Logic here to get current user role
  const { user } = useAuthContext();

  // const currentRole = 'user';
  const currentRoles = user?.claims.filter(x => x.type === 'role'); // admin;

  if (typeof roles !== 'undefined' && !currentRoles?.some(x => roles.includes(x.value))) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Acesso negado
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Você não tem permissão para acessar essa página
          </Typography>
        </m.div>

        <m.br />

        <Button component={RouterLink} to="/" size="large" variant="contained">
          Início
        </Button>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}
