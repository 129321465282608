import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));

// USER
export const UserListPage = Loadable(lazy(() => import('../pages/user/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/user/UserAccountPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/user/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/user/UserEditPage')));
export const UserProfilePage = Loadable(lazy(() => import('../pages/user/UserProfilePage')));

// EVENT
export const EventListPage = Loadable(lazy(() => import('../pages/event/EventListPage')));
export const EventCreatePage = Loadable(lazy(() => import('../pages/event/EventCreatePage')));
export const EventEditPage = Loadable(lazy(() => import('../pages/event/EventEditPage')));
export const EventConfirmationPage = Loadable(lazy(() => import('../pages/event/EventConfirmationPage')));
export const EventCredentialPage = Loadable(lazy(() => import('../pages/event/EventCredentialPage')));

export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
