export default function errorHandling(error) {
  let response = "Erro ao realizar o processo";

  try {
    response = error.errors[0];
  } catch {
    try {
      response = error.response[0];
    } catch {
      return response;
    }
  }

  return response;
}